<template>
  <div class="container">
    <div class="rule-part">
      <h5 class="title">个人信息填报规则</h5>
      <p>A.请认真填写个人基本信息，并保证信息的真实性。</p>
      <p>B.当常驻地址或组织结构发生变化时请及时修改。</p>
    </div>
    <div class="rule-part">
      <h5 class="title"> 行程登记填报规则</h5>
      <p>
        当位置发生较大变动时如跨国、跨省、夸区以及 距离发生较大变动，请及时上报行程记录。如有遗漏 请及时补充或修改。
      </p>
    </div>
    <div class="rule-part">
      <h5 class="title">核酸检测填报规则</h5>
      <p>请及时填写核酸检测记录，并保证所填写信息的 准确性。</p>
    </div>
    <div class="rule-part">
      <h5 class="title">疫苗接种填报规则</h5>
      <p>
        请及时填写疫苗接种信息。
      </p>
    </div>
    <div class="rule-part">
      <h5 class="title">隔离/检测填报规则 </h5>
      <p>如发展隔离或处于健康检测状态请立即登记。并认真 添加相关信息，保证信息的真实性。</p>
    </div>
    <div class="rule-part">
      <h5 class="title">体温登记填报规则</h5>
      <p>人员处于集中隔离、居家隔离、健康检测状态时，需每日登记提问信息。</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  padding:0.30rem 0.20rem;
}
.rule-part {
  margin-bottom: 0.12rem;
  border-bottom: 1px dashed #707070;
}
.rule-part .title {
  color: #3D3D3D;
  font-size: 0.16rem;
  line-height: 0.15rem;
  margin-bottom: 0.12rem;
  position: relative;
}
.rule-part p {
  color: #3D3D3D;
  line-height: 1.6;
  margin: 0.04rem 0;
}
/* .rule-part .title::before{
    content: '';
    position: absolute;
    background: rgba(79, 119, 170, 1);
    width: 0.20rem;
    height: 0.20rem;
    border-radius: 50%;
    text-align: center;
    line-height: 0.20rem;
    font-size: 12px;
    color: 1;
    left: 0;
    transform: translate(calc(-100% - 0.10rem),-50%);
    top: 50%;
    color: #fff;

} */
/* .rule-part:nth-child(1) .title:before{
    content: '1';
}
.rule-part:nth-child(2) .title:before{
    content: '2';
}
.rule-part:nth-child(3) .title:before{
    content: '3';
}
.rule-part:nth-child(4) .title:before{
    content: '4';
}
.rule-part:nth-child(5) .title:before{
    content: '5';
} */

</style>